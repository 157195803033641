import './App.css';
import { Link, Stack, TextField, Paper, IconButton } from '@mui/material';
import React, { useState, useEffect } from 'react';
import ApyInfoCard from './components/ApyInfoCard/ApyInfoCard';
import RewardInfoCard from './components/RewardInfoCard/RewardInfoCard';
import LiquidityTableCard from './components/LiquidityTableCard/LiquidityTableCard';
import { createTheme, ThemeProvider, CssBaseline } from '@mui/material';
import { LightMode, DarkMode } from '@mui/icons-material';

const lightTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#A1C1B0',
    },
    secondary: {
      main: '#397955',
    },
    background: {
      default: '#ffffff',
      paper: '#d2edd3',
    },
  },
});

const darkTheme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#656D4A',
    },
    secondary: {
      main: '#A4AC86',
    },
    background: {
      default: '#121212',
      paper: '#333834',
    },
    text: {
      primary: '#E0E0E0',
      secondary: '#E0E0E0'
    }
  },
});

function App() {
  const [addr, setAddr] = useState(() => {
    const savedAddr = localStorage.getItem("address");
    return savedAddr || "";
  });

  const [theme, setTheme] = useState(() => {
    const savedTheme = localStorage.getItem("theme");
    return savedTheme || "lightTheme";
  })

  function changeTheme() {
    if (theme === 'lightTheme') {
      setTheme('darkTheme');
      localStorage.setItem("theme", "darkTheme");
    } else {
      setTheme('lightTheme');
      localStorage.setItem("theme", "lightTheme");
    }
  };

  useEffect(() => {
    document.title = 'NORTSO'
  }, []);

  function updateAddress(event) {
    setAddr(event.target.value);
    localStorage.setItem("address", event.target.value);
  }

  let themeButton;
  if (theme === 'lightTheme') {
    themeButton = <DarkMode/>;
  } else {
    themeButton = <LightMode sx={{ color:'yellow' }}/>;
  }

  return (
    <ThemeProvider theme={theme === 'lightTheme' ? lightTheme : darkTheme}>
      <CssBaseline />
      <Paper square>
        <IconButton onClick={changeTheme}>
          {themeButton}
        </IconButton>
        <div className='flex column center body'>
          <Stack direction='row' spacing='2px' justifyContent='center'>
            <span>NORTSO address: </span>
            <Link color='secondary' href='https://songbird-explorer.flare.network/address/0x04a8b3171fBbfe4554B55190B43E709c4b672030/transactions' target="_blank">0x04a8b3171fBbfe4554B55190B43E709c4b672030</Link>
          </Stack>
          <div className='center' style={{ maxWidth: '800px', alignSelf: 'center', textAlign: 'center', margin: '10px' }}>
            Welcome to NORTSO. I provide data to the STSO system in addition to some analysis of the FlrFinance ecosystem you can use here. Enter your address below to get information about your earnings in FlrX, FlrFarm, and FlrLoans.
          </div>
          <div className='center' style={{ maxWidth: 450 }}>
            <TextField id='addr' type='text' helperText='Your Songbird Address' defaultValue={addr} fullWidth onChange={updateAddress} />
          </div>
        </div>
      </Paper>
      <div className='center flex row wrap'>
        <LiquidityTableCard addr={addr} />
        <RewardInfoCard addr={addr} />
        <ApyInfoCard />
      </div>
    </ThemeProvider >
  );
}

export default App;
