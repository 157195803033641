import { Component } from 'react'
import Card from '@mui/material/Card';
import { Table, TableHead, TableRow, TableCell, TableContainer, TableBody } from '@mui/material'
import { LoadingButton } from '@mui/lab';
import './LiquidityTableCard.css'

class LiquidityTableCard extends Component {
    constructor(props) {
        super(props);

        const currentRows = [];
        const historicalRows = [];
        const sgbCandCurrentRow = localStorage.getItem('SgbCandCurrentRow');
        const sgbSfinCurrentRow = localStorage.getItem('SgbSfinCurrentRow');
        const exfiCandCurrentRow = localStorage.getItem('ExfiCandCurrentRow');
        const sfinCandCurrentRow = localStorage.getItem('SfinCandCurrentRow');
        const exfiSgbCurrentRow = localStorage.getItem('ExfiSgbCurrentRow');
        const exfiSfinCurrentRow = localStorage.getItem('ExfiSfinCurrentRow');
        const sgbCootCurrentRow = localStorage.getItem('SgbCootCurrentRow');
        const exXdcSgbCurrentRow = localStorage.getItem('ExXdcSgbCurrentRow');
        const exXdcExfiCurrentRow = localStorage.getItem('ExXdcExfiCurrentRow');
        const sgbCandHistoricalRow = localStorage.getItem('SgbCandHistoricalRow');
        const sgbSfinHistoricalRow = localStorage.getItem('SgbSfinHistoricalRow');
        const exfiCandHistoricalRow = localStorage.getItem('ExfiCandHistoricalRow');
        const sfinCandHistoricalRow = localStorage.getItem('SfinCandHistoricalRow');
        const exfiSgbHistoricalRow = localStorage.getItem('ExfiSgbHistoricalRow');
        const exfiSfinHistoricalRow = localStorage.getItem('ExfiSfinHistoricalRow');
        const sgbCootHistoricalRow = localStorage.getItem('SgbCootHistoricalRow');
        const exXdcSgbHistoricalRow = localStorage.getItem('exXdcSgbHistoricalRow');
        const exXdcExfiHistoricalRow = localStorage.getItem('exXdcExfiHistoricalRow');
        
        if (sgbCandCurrentRow) { currentRows.push(JSON.parse(sgbCandCurrentRow)); }
        if (sgbSfinCurrentRow) { currentRows.push(JSON.parse(sgbSfinCurrentRow)); }
        if (exfiCandCurrentRow) { currentRows.push(JSON.parse(exfiCandCurrentRow)); }
        if (sfinCandCurrentRow) { currentRows.push(JSON.parse(sfinCandCurrentRow)); }
        if (exfiSgbCurrentRow) { currentRows.push(JSON.parse(exfiSgbCurrentRow)); }
        if (exfiSfinCurrentRow) { currentRows.push(JSON.parse(exfiSfinCurrentRow)); }
        if (sgbCootCurrentRow) { currentRows.push(JSON.parse(sgbCootCurrentRow)); }
        if (exXdcSgbCurrentRow) { currentRows.push(JSON.parse(exXdcSgbCurrentRow)); }
        if (exXdcExfiCurrentRow) { currentRows.push(JSON.parse(exXdcExfiCurrentRow)); }

        if (sgbCandHistoricalRow) { historicalRows.push(JSON.parse(sgbCandHistoricalRow)); }
        if (sgbSfinHistoricalRow) { historicalRows.push(JSON.parse(sgbSfinHistoricalRow)); }
        if (exfiCandHistoricalRow) { historicalRows.push(JSON.parse(exfiCandHistoricalRow)); }
        if (sfinCandHistoricalRow) { historicalRows.push(JSON.parse(sfinCandHistoricalRow)); }
        if (exfiSgbHistoricalRow) { historicalRows.push(JSON.parse(exfiSgbHistoricalRow)); }
        if (exfiSfinHistoricalRow) { historicalRows.push(JSON.parse(exfiSfinHistoricalRow)); }
        if (sgbCootHistoricalRow) { historicalRows.push(JSON.parse(sgbCootHistoricalRow)); }
        if (exXdcSgbHistoricalRow) { historicalRows.push(JSON.parse(exXdcSgbHistoricalRow)); }
        if (exXdcExfiHistoricalRow) { historicalRows.push(JSON.parse(exXdcExfiHistoricalRow)); }

        this.state = {
            loading: false,
            rowsCurrent: currentRows.length > 0 ? currentRows : null,
            rowsHistorical: historicalRows.length > 0 ? historicalRows : null
        }
    }

    createCurrentRow = (lpTokenName, data, tokenPrecision, basePrecision) => {
        return { 
            lpTokenName,
            qtyLpToken: data.qtyLpToken,
            poolShare: `${data.poolShare}%`,
            qtyToken: `${data.qtyToken.toFixed(tokenPrecision)} ${data.tokenName}`,
            qtyBase: `${data.qtyBase.toFixed(basePrecision)} ${data.baseName}`,
            impermanentLoss: `${data.impermanentLoss}%`,
            impermanentLossToken: `${data.impermanentLossToken.toFixed(tokenPrecision)} ${data.tokenName}`,
            impermanentLossBase: `${data.impermanentLossBase.toFixed(basePrecision)} ${data.baseName}`,
            pendingTokenFees: `${data.pendingTokenFees.toFixed(tokenPrecision)} ${data.tokenName}`,
            pendingBaseFees: `${data.pendingBaseFees.toFixed(basePrecision)} ${data.baseName}`
        }
    };

    createHistoricalRow = (lpTokenName, data, tokenPrecision, basePrecision) => {
        return { 
            lpTokenName,
            permanentTokenLoss: `${data.permanentTokenLoss.toFixed(tokenPrecision)} ${data.tokenName}`,
            permanentBaseLoss: `${data.permanentBaseLoss.toFixed(basePrecision)} ${data.baseName}`,
            claimedTokenFees: `${data.claimedTokenFees.toFixed(tokenPrecision)} ${data.tokenName}`,
            claimedBaseFees: `${data.claimedBaseFees.toFixed(basePrecision)} ${data.baseName}`
        }
    };

    refreshStats = async () => {
        this.setState({
            loading: true
        });

        const rowsCurrent = [];
        const rowsHistorical = [];
        const tasks = [
            fetch(`https://flrfinance-functions.azurewebsites.net/api/getLiquidityData?addr=${this.props.addr}&lpToken=sgbcand`)
                .then(res => res.json())
                .then(json => {
                    if (json.qtyLpToken > 0) {
                        var row = this.createCurrentRow('SgbCand', json, 1, 1);
                        localStorage.setItem('SgbCandCurrentRow', JSON.stringify(row));
                        rowsCurrent.push(row);
                    }
                    else {
                        localStorage.removeItem('SgbCandCurrentRow');
                    }
                    row = this.createHistoricalRow('SgbCand', json, 1, 1);
                    rowsHistorical.push(row);
                    localStorage.setItem('SgbCandHistoricalRow', JSON.stringify(row));
                })
                .catch(err => console.log(err)),
            fetch(`https://flrfinance-functions.azurewebsites.net/api/getLiquidityData?addr=${this.props.addr}&lpToken=sgbsfin`)
                .then(res => res.json())
                .then(json => {
                    if (json.qtyLpToken > 0) {
                        var row = this.createCurrentRow('SgbSfin', json, 1, 1);
                        localStorage.setItem('SgbSfinCurrentRow', JSON.stringify(row));
                        rowsCurrent.push(row);
                    }
                    else {
                        localStorage.removeItem('SgbSfinCurrentRow');
                    }
                    row = this.createHistoricalRow('SgbSfin', json, 1, 1);
                    rowsHistorical.push(row);
                    localStorage.setItem('SgbSfinHistoricalRow', JSON.stringify(row));
                })
                .catch(err => console.log(err)),
            fetch(`https://flrfinance-functions.azurewebsites.net/api/getLiquidityData?addr=${this.props.addr}&lpToken=exficand`)
                .then(res => res.json())
                .then(json => {
                    if (json.qtyLpToken > 0) {
                        var row = this.createCurrentRow('ExfiCand', json, 1, 1);
                        localStorage.setItem('ExfiCandCurrentRow', JSON.stringify(row));
                        rowsCurrent.push(row);
                    }
                    else {
                        localStorage.removeItem('ExfiCandCurrentRow');
                    }
                    row = this.createHistoricalRow('ExfiCand', json);
                    rowsHistorical.push(row);
                    localStorage.setItem('ExfiCandHistoricalRow', JSON.stringify(row));
                })
                .catch(err => console.log(err)),
            fetch(`https://flrfinance-functions.azurewebsites.net/api/getLiquidityData?addr=${this.props.addr}&lpToken=sfincand`)
                .then(res => res.json())
                .then(json => {
                    if (json.qtyLpToken > 0) {
                        var row = this.createCurrentRow('SfinCand', json, 4, 1);
                        localStorage.setItem('SfinCandCurrentRow', JSON.stringify(row));
                        rowsCurrent.push(row);
                    } else {
                        localStorage.removeItem('SfinCandCurrentRow');
                    }
                    row = this.createHistoricalRow('SfinCand', json, 4, 1);
                    rowsHistorical.push(row);
                    localStorage.setItem('SfinCandHistoricalRow', JSON.stringify(row));
                })
                .catch(err => console.log(err)),
            fetch(`https://flrfinance-functions.azurewebsites.net/api/getLiquidityData?addr=${this.props.addr}&lpToken=exfisgb`)
                .then(res => res.json())
                .then(json => {
                    if (json.qtyLpToken > 0) {
                        var row = this.createCurrentRow('ExfiSgb', json, 2, 1);
                        localStorage.setItem('ExfiSgbCurrentRow', JSON.stringify(row));
                        rowsCurrent.push(row);
                    } else {
                        localStorage.removeItem('ExfiSgbCurrentRow');
                    }
                    row = this.createHistoricalRow('ExfiSgb', json, 2, 1);
                    rowsHistorical.push(row);
                    localStorage.setItem('ExfiSgbHistoricalRow', JSON.stringify(row));
                })
                .catch(err => console.log(err)),
            fetch(`https://flrfinance-functions.azurewebsites.net/api/getLiquidityData?addr=${this.props.addr}&lpToken=exfisfin`)
                .then(res => res.json())
                .then(json => {
                    if (json.qtyLpToken > 0) {
                        var row = this.createCurrentRow('ExfiSfin', json, 2, 4);
                        localStorage.setItem('ExfiSfinCurrentRow', JSON.stringify(row));
                        rowsCurrent.push(row);
                    } else {
                        localStorage.removeItem('ExfiSfinCurrentRow');
                    }
                    row = this.createHistoricalRow('ExfiSfin', json, 2, 4);
                    rowsHistorical.push(row);
                    localStorage.setItem('ExfiSfinHistoricalRow', JSON.stringify(row));
                })
                .catch(err => console.log(err)),
            fetch(`https://flrfinance-functions.azurewebsites.net/api/getLiquidityData?addr=${this.props.addr}&lpToken=sgbcoot`)
                .then(res => res.json())
                .then(json => {
                    if (json.qtyLpToken > 0) {
                        var row = this.createCurrentRow('SgbCoot', json, 1, 1);
                        localStorage.setItem('SgbCootCurrentRow', JSON.stringify(row));
                        rowsCurrent.push(row);
                    } else {
                        localStorage.removeItem('SgbCootCurrentRow');
                    }
                    row = this.createHistoricalRow('SgbCoot', json, 1, 1);
                    rowsHistorical.push(row);
                    localStorage.setItem('SgbCootHistoricalRow', JSON.stringify(row));
                })
                .catch(err => console.log(err)),
            fetch(`https://flrfinance-functions.azurewebsites.net/api/getLiquidityData?addr=${this.props.addr}&lpToken=exXdcSgb`)
                .then(res => res.json())
                .then(json => {
                    if (json.qtyLpToken > 0) {
                        var row = this.createCurrentRow('ExXdcSgb', json, 1, 1);
                        localStorage.setItem('ExXdcSgbCurrentRow', JSON.stringify(row));
                        rowsCurrent.push(row);
                    } else {
                        localStorage.removeItem('ExXdcSgbCurrentRow');
                    }
                    row = this.createHistoricalRow('ExXdcSgb', json, 1, 1);
                    rowsHistorical.push(row);
                    localStorage.setItem('ExXdcSgbHistoricalRow', JSON.stringify(row));
                })
                .catch(err => console.log(err)),
            fetch(`https://flrfinance-functions.azurewebsites.net/api/getLiquidityData?addr=${this.props.addr}&lpToken=exXdcExfi`)
                .then(res => res.json())
                .then(json => {
                    if (json.qtyLpToken > 0) {
                        var row = this.createCurrentRow('ExXdcExfi', json, 1, 1);
                        localStorage.setItem('ExXdcExfiCurrentRow', JSON.stringify(row));
                        rowsCurrent.push(row);
                    } else {
                        localStorage.removeItem('ExXdcExfiCurrentRow');
                    }
                    row = this.createHistoricalRow('ExXdcExfi', json, 1, 1);
                    rowsHistorical.push(row);
                    localStorage.setItem('ExXdcExfiHistoricalRow', JSON.stringify(row));
                })
                .catch(err => console.log(err))
        ];
        await Promise.allSettled(tasks);
        this.setState({
            loading: false,
            rowsCurrent,
            rowsHistorical
        });
    };

    render() {
        return (
            <Card raised className="liquidityTableCard">
                <div style={{display:'flex', flexDirection:'column'}}>
                    <span style={{ fontWeight:'bold' }}>Your liquidity data</span>
                    <span style={{ textAlign:'center', marginTop:'20px', fontWeight:'bold' }}>Current liquidity position</span>
                    { this.state.rowsCurrent ?
                        <TableContainer>
                            <Table size='small' padding='checkbox'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={1}/>
                                        <TableCell colSpan={4} align='left' sx={{ fontWeight:'bold' }}>Your position</TableCell>
                                        <TableCell colSpan={3} align='left' sx={{ fontWeight:'bold' }}>Impermanent loss</TableCell>
                                        <TableCell colSpan={2} align='left' sx={{ fontWeight:'bold' }}>Pending fees</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight:'bold' }}>LP</TableCell>
                                        <TableCell sx={{ fontWeight:'bold' }}>LP tokens</TableCell>
                                        <TableCell sx={{ fontWeight:'bold' }}>Pool share</TableCell>
                                        <TableCell sx={{ fontWeight:'bold' }}>Token</TableCell>
                                        <TableCell sx={{ fontWeight:'bold' }}>Base</TableCell>
                                        <TableCell sx={{ fontWeight:'bold' }}>IL</TableCell>
                                        <TableCell sx={{ fontWeight:'bold' }}>Token</TableCell>
                                        <TableCell sx={{ fontWeight:'bold' }}>Base</TableCell>
                                        <TableCell sx={{ fontWeight:'bold' }}>Token</TableCell>
                                        <TableCell sx={{ fontWeight:'bold' }}>Base</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.rowsCurrent.map((row) => (
                                        <TableRow key={row.lpTokenName} sx={{ '&:last-child td, &:last-child th': {border: 0} }}>
                                            <TableCell>{row.lpTokenName}</TableCell>
                                            <TableCell>{row.qtyLpToken}</TableCell>
                                            <TableCell>{row.poolShare}</TableCell>
                                            <TableCell>{row.qtyToken}</TableCell>
                                            <TableCell>{row.qtyBase}</TableCell>
                                            <TableCell>{row.impermanentLoss}</TableCell>
                                            <TableCell>{row.impermanentLossToken}</TableCell>
                                            <TableCell>{row.impermanentLossBase}</TableCell>
                                            <TableCell>{row.pendingTokenFees}</TableCell>
                                            <TableCell>{row.pendingBaseFees}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        : 
                        <span style={{textAlign:'center'}}>No data</span>
                    }
                    <span style={{ textAlign:'center', marginTop:'20px', fontWeight:'bold' }}>Historical data</span>
                    {this.state.rowsHistorical ?
                        <TableContainer>
                            <Table size='small' padding='checkbox'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={1} sx={{ fontWeight:'bold' }}/>
                                        <TableCell colSpan={2} align='left' sx={{ fontWeight:'bold' }}>Permanent loss</TableCell>
                                        <TableCell colSpan={2} align='left' sx={{ fontWeight:'bold' }}>Claimed fees</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight:'bold' }}>LP token</TableCell>
                                        <TableCell sx={{ fontWeight:'bold' }}>Token</TableCell>
                                        <TableCell sx={{ fontWeight:'bold' }}>Base</TableCell>
                                        <TableCell sx={{ fontWeight:'bold' }}>Token</TableCell>
                                        <TableCell sx={{ fontWeight:'bold' }}>Base</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {this.state.rowsHistorical.map((row) => (
                                        <TableRow key={row.lpTokenName} sx={{ '&:last-child td, &:last-child th': {border: 0} }}>
                                            <TableCell>{row.lpTokenName}</TableCell>
                                            <TableCell>{row.permanentTokenLoss}</TableCell>
                                            <TableCell>{row.permanentBaseLoss}</TableCell>
                                            <TableCell>{row.claimedTokenFees}</TableCell>
                                            <TableCell>{row.claimedBaseFees}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        : 
                        <span style={{alignSelf:'center'}}>No data</span>
                    }
                    <div style={{ alignSelf:'flex-end', marginTop:'10px' }}>
                        <LoadingButton loading={ this.state.loading } size='small' variant='contained' onClick={ () => this.refreshStats() }>Update</LoadingButton>
                    </div>
                </div>
            </Card>
        );
    }
}

export default LiquidityTableCard;