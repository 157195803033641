import { Component } from 'react'
import Card from '@mui/material/Card';
import './ApyInfoCard.css'

class ApyInfoCard extends Component
{
    constructor(props) {
        super(props);
        this.state = {
            sgbCandApy: 0,
            sgbSfinApy: 0,
            exfiCandApy: 0,
            sfinCandApy: 0,
            exfiSgbApy: 0,
            exXdcSgbApy: 0,
            exXdcExfiApy: 0,
            exfiSfinApy: 0,
            exfiSfin180dApy: 0,
            sgbCootApy: 0,
            wsgbApy: 0,
            exfiApy: 0,
            exfi180dV5Apy: 0,
            exfi180dV6Apy: 0,
            sgbSpApy: 0,
            exfiSpApy: 0
        }
    }

    componentDidMount() {
        this.refreshAPYs()
        setInterval(() => {
            this.refreshAPYs();
        }, 300000)
    }

    refreshAPYs = () => {
        fetch('https://flrfinance-functions.azurewebsites.net/api/getApys')
        .then(response => response.json())
        .then(json => {
            this.setState(json);
        })
        .catch(err => console.log(err));
    }

    render() {
        return(
            <Card color="primary" raised className='apyCard'>
                <div className='flex column'>
                    <div style={{ fontWeight:'bold' }}>APYs</div>
                    <div className='sectionInfo' >Liquidity pools</div>
                    <div className='infoBox'>
                        <div className='apyLine'>
                            <span>SgbCand</span>
                            <span> { this.state.sgbCandApy}%</span>
                        </div>
                        <div className='apyLine'>
                            <span>SgbSfin</span>
                            <span> { this.state.sgbSfinApy}%</span>
                        </div>
                        <div className='apyLine'>
                            <span>ExfiCand</span>
                            <span>{ this.state.exfiCandApy }%</span>
                        </div>
                        <div className='apyLine'>
                            <span>SfinCand</span>
                            <span>{ this.state.sfinCandApy }%</span>
                        </div>
                        <div className='apyLine'>
                            <span>ExfiSgb</span>
                            <span>{ this.state.exfiSgbApy }%</span>
                        </div>
                        <div className='apyLine'>
                            <span>ExfiSfin</span>
                            <span>{ this.state.exfiSfinApy }%</span>
                        </div>
                        <div className='apyLine'>
                            <span>ExXdcSgb</span>
                            <span> { this.state.exXdcSgbApy }%</span>
                        </div>
                        <div className='apyLine'>
                            <span>ExXdcExfi</span>
                            <span> { this.state.exXdcExfiApy }%</span>
                        </div>
                        <div className='apyLine'>
                            <span>ExfiSfin (180d)</span>
                            <span>{ this.state.exfiSfin180dApy }%</span>
                        </div>
                        <div className='apyLine'>
                            <span>SgbCoot</span>
                            <span>{ this.state.sgbCootApy }%</span>
                        </div>
                    </div>
                    <div className='sectionInfo'>Token staking</div>
                    <div className='infoBox' style={{ marginTop: '10px' }} >
                        <div className='apyLine'>
                            <span>Wsgb</span>
                            <span>{ this.state.wsgbApy }%</span>
                        </div>
                        <div className='apyLine'>
                            <span>Exfi</span>
                            <span>{ this.state.exfiApy }%</span>
                        </div>
                        <div className='apyLine'>
                            <span>Exfi (180d v5)</span>
                            <span>{ this.state.exfi180dV5Apy }%</span>
                        </div>
                        <div className='apyLine'>
                            <span>Exfi (180d v6)</span>
                            <span>{ this.state.exfi180dV6Apy }%</span>
                        </div>
                    </div>
                    <div className='sectionInfo'>Stability pools</div>
                    <div className='infoBox'>
                        <div className='apyLine'>
                            <span>Sgb loans</span>
                            <span>{ this.state.sgbSpApy }%</span>
                        </div>
                        <div className='apyLine'>
                            <span>Exfi loans</span>
                            <span>{ this.state.exfiSpApy }%</span>
                        </div>
                    </div>
                </div>
            </Card>
        );
    }
}

export default ApyInfoCard;