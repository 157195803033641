import { Component } from 'react'
import Card from '@mui/material/Card';
import { LoadingButton } from '@mui/lab';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableFooter, Tooltip, Icon, Stack } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import "./RewardInfoCard.css"

class RewardData {
    sfinClaimed = 0;
    valueClaimed = 0;

    constructor(_sfinClaimed, _valueClaimed) {
        this.sfinClaimed = _sfinClaimed;
        this.valueClaimed = _valueClaimed;
    }
}

class RewardInfoCard extends Component {
    constructor(props) {
        super(props);

        const savedSgbCandRewardData = localStorage.getItem("sgbCandRewardData");
        const savedSgbSfinRewardData = localStorage.getItem("sgbSfinRewardData");
        const savedExfiCandRewardData = localStorage.getItem("exfiCandRewardData");
        const savedSfinCandRewardData = localStorage.getItem("sfinCandRewardData");
        const savedExfiSgbRewardData = localStorage.getItem("exfiSgbRewardData");
        const savedExfiSfinRewardData = localStorage.getItem("exfiSfinRewardData");
        const savedExXdcSgbRewardData = localStorage.getItem("exXdcSgbRewardData");
        const savedExXdcExfiRewardData = localStorage.getItem("exXdcExfiRewardData");
        const savedExfiSfin90dRewardData = localStorage.getItem("exfiSfin90dRewardData");
        const savedExfiSfin180dRewardData = localStorage.getItem("exfiSfin180dRewardData");
        const savedExfiSfin180dV2RewardData = localStorage.getItem("exfiSfin180dV2RewardData");
        const savedExfiSfin180dV3RewardData = localStorage.getItem("exfiSfin180dV3RewardData");
        const savedWsgbRewardData = localStorage.getItem("wsgbRewardData");
        const savedExfiRewardData = localStorage.getItem("exfiRewardData");
        const savedExfi90dRewardData = localStorage.getItem("exfi90dRewardData");
        const savedExfi180dRewardData = localStorage.getItem("exfi180dRewardData");
        const savedExfi180dV2RewardData = localStorage.getItem("exfi180dV2RewardData");
        const savedExfi180dV3RewardData = localStorage.getItem("exfi180dV3RewardData");
        const savedExfi180dV4RewardData = localStorage.getItem("exfi180dV4RewardData");
        const savedExfi180dV5RewardData = localStorage.getItem("exfi180dV5RewardData");
        const savedExfi180dV6RewardData = localStorage.getItem("exfi180dV6RewardData");
        const savedSfinRewardData = localStorage.getItem("sfinRewardData");
        const savedCandRewardData = localStorage.getItem("candRewardData");
        const savedSgbSpRewardData = localStorage.getItem("sgbSpRewardData");
        const savedExfiSpRewardData = localStorage.getItem("exfiSpRewardData");

        this.state = {
            sgbCandRewardData: savedSgbCandRewardData ? JSON.parse(savedSgbCandRewardData) : new RewardData(0, 0),
            sgbSfinRewardData: savedSgbSfinRewardData ? JSON.parse(savedSgbSfinRewardData) : new RewardData(0, 0),
            exfiCandRewardData: savedExfiCandRewardData ? JSON.parse(savedExfiCandRewardData) : new RewardData(0, 0),
            sfinCandRewardData: savedExfiCandRewardData ? JSON.parse(savedSfinCandRewardData) : new RewardData(0, 0),
            exfiSgbRewardData: savedExfiSgbRewardData ? JSON.parse(savedExfiSgbRewardData) : new RewardData(0, 0),
            exfiSfinRewardData: savedExfiSfinRewardData ? JSON.parse(savedExfiSfinRewardData) : new RewardData(0, 0),
            exXdcSgbRewardData: savedExXdcSgbRewardData ? JSON.parse(savedExXdcSgbRewardData) : new RewardData(0, 0),
            exXdcExfiRewardData: savedExXdcExfiRewardData ? JSON.parse(savedExXdcExfiRewardData) : new RewardData(0, 0),
            exfiSfin90dRewardData: savedExfiSfin90dRewardData ? JSON.parse(savedExfiSfin90dRewardData) : new RewardData(0, 0),
            exfiSfin180dRewardData: savedExfiSfin180dRewardData ? JSON.parse(savedExfiSfin180dRewardData) : new RewardData(0, 0),
            exfiSfin180dV2RewardData: savedExfiSfin180dV2RewardData ? JSON.parse(savedExfiSfin180dV2RewardData) : new RewardData(0, 0),
            exfiSfin180dV3RewardData: savedExfiSfin180dV3RewardData ? JSON.parse(savedExfiSfin180dV3RewardData) : new RewardData(0, 0),
            wsgbRewardData: savedWsgbRewardData ? JSON.parse(savedWsgbRewardData) : new RewardData(0, 0),
            exfiRewardData: savedExfiRewardData ? JSON.parse(savedExfiRewardData) : new RewardData(0, 0),
            exfi90dRewardData: savedExfi90dRewardData ? JSON.parse(savedExfi90dRewardData) : new RewardData(0, 0),
            exfi180dRewardData: savedExfi180dRewardData ? JSON.parse(savedExfi180dRewardData) : new RewardData(0, 0),
            exfi180dV2RewardData: savedExfi180dV2RewardData ? JSON.parse(savedExfi180dV2RewardData) : new RewardData(0, 0),
            exfi180dV3RewardData: savedExfi180dV3RewardData ? JSON.parse(savedExfi180dV3RewardData) : new RewardData(0, 0),
            exfi180dV4RewardData: savedExfi180dV4RewardData ? JSON.parse(savedExfi180dV4RewardData) : new RewardData(0, 0),
            exfi180dV5RewardData: savedExfi180dV5RewardData ? JSON.parse(savedExfi180dV5RewardData) : new RewardData(0, 0),
            exfi180dV6RewardData: savedExfi180dV6RewardData ? JSON.parse(savedExfi180dV6RewardData) : new RewardData(0, 0),
            sfinRewardData: savedSfinRewardData ? JSON.parse(savedSfinRewardData) : new RewardData(0, 0),
            candRewardData: savedCandRewardData ? JSON.parse(savedCandRewardData) : new RewardData(0, 0),
            sgbSpRewardData: savedSgbSpRewardData ? JSON.parse(savedSgbSpRewardData) : new RewardData(0, 0),
            exfiSpRewardData: savedExfiSpRewardData ? JSON.parse(savedExfiSpRewardData) : new RewardData(0, 0),
            loading: false
        }
    }

    updateClicked = async () => {
        this.setState({
            loading: true
        });

        var sgbCandPromise = fetch(`https://flrfinance-functions.azurewebsites.net/api/getRewards?addr=${this.props.addr}&lpToken=SgbCand`);
        var sgbSfinPromise = fetch(`https://flrfinance-functions.azurewebsites.net/api/getRewards?addr=${this.props.addr}&lpToken=SgbSfin`);
        var exfiCandPromise = fetch(`https://flrfinance-functions.azurewebsites.net/api/getRewards?addr=${this.props.addr}&lpToken=ExfiCand`);
        var sfinCandPromise = fetch(`https://flrfinance-functions.azurewebsites.net/api/getRewards?addr=${this.props.addr}&lpToken=SfinCand`);
        var exfiSgbPromise = fetch(`https://flrfinance-functions.azurewebsites.net/api/getRewards?addr=${this.props.addr}&lpToken=ExfiSgb`);
        var exfiSfinPromise = fetch(`https://flrfinance-functions.azurewebsites.net/api/getRewards?addr=${this.props.addr}&lpToken=ExfiSfin`);
        var exXdcSgbPromise = fetch(`https://flrfinance-functions.azurewebsites.net/api/getRewards?addr=${this.props.addr}&lpToken=ExXdcSgb`);
        var exXdcExfiPromise = fetch(`https://flrfinance-functions.azurewebsites.net/api/getRewards?addr=${this.props.addr}&lpToken=ExXdcExfi`);
        var exfiSfin90dPromise = fetch(`https://flrfinance-functions.azurewebsites.net/api/getRewards?addr=${this.props.addr}&lpToken=ExfiSfin&lockupType=days90`);
        var exfiSfin180dPromise = fetch(`https://flrfinance-functions.azurewebsites.net/api/getRewards?addr=${this.props.addr}&lpToken=ExfiSfin&lockupType=days180`);
        var exfiSfin180dV2Promise = fetch(`https://flrfinance-functions.azurewebsites.net/api/getRewards?addr=${this.props.addr}&lpToken=ExfiSfin&lockupType=days180_v2`);
        var exfiSfin180dV3Promise = fetch(`https://flrfinance-functions.azurewebsites.net/api/getRewards?addr=${this.props.addr}&lpToken=ExfiSfin&lockupType=days180_v3`);
        var wsgbPromise = fetch(`https://flrfinance-functions.azurewebsites.net/api/getRewards?addr=${this.props.addr}&token=Wsgb`);
        var exfiPromise = fetch(`https://flrfinance-functions.azurewebsites.net/api/getRewards?addr=${this.props.addr}&token=Exfi`);
        var exfi90dPromise = fetch(`https://flrfinance-functions.azurewebsites.net/api/getRewards?addr=${this.props.addr}&token=Exfi&lockupType=days90`);
        var exfi180dPromise = fetch(`https://flrfinance-functions.azurewebsites.net/api/getRewards?addr=${this.props.addr}&token=Exfi&lockupType=days180`);
        var exfi180dV2Promise = fetch(`https://flrfinance-functions.azurewebsites.net/api/getRewards?addr=${this.props.addr}&token=Exfi&lockupType=days180_v2`);
        var exfi180dV3Promise = fetch(`https://flrfinance-functions.azurewebsites.net/api/getRewards?addr=${this.props.addr}&token=Exfi&lockupType=days180_v3`);
        var exfi180dV4Promise = fetch(`https://flrfinance-functions.azurewebsites.net/api/getRewards?addr=${this.props.addr}&token=Exfi&lockupType=days180_v4`);
        var exfi180dV5Promise = fetch(`https://flrfinance-functions.azurewebsites.net/api/getRewards?addr=${this.props.addr}&token=Exfi&lockupType=days180_v5`);
        var exfi180dV6Promise = fetch(`https://flrfinance-functions.azurewebsites.net/api/getRewards?addr=${this.props.addr}&token=Exfi&lockupType=days180_v6`);
        var sfinPromise = fetch(`https://flrfinance-functions.azurewebsites.net/api/getRewards?addr=${this.props.addr}&token=Sfin`);
        var candPromise = fetch(`https://flrfinance-functions.azurewebsites.net/api/getRewards?addr=${this.props.addr}&token=Cand`);
        var sgbSpPromise = fetch(`https://flrfinance-functions.azurewebsites.net/api/getRewards?addr=${this.props.addr}&stabilityPoolType=Sgb`);
        var exfiSpPromise = fetch(`https://flrfinance-functions.azurewebsites.net/api/getRewards?addr=${this.props.addr}&stabilityPoolType=Exfi`);

        const tasks = [
            sgbCandPromise.then(response => response.json()).then(json => this.setState({ sgbCandRewardData: json })).catch(err => console.log(err)),
            sgbSfinPromise.then(response => response.json()).then(json => this.setState({ sgbSfinRewardData: json })).catch(err => console.log(err)),
            exfiCandPromise.then(response => response.json()).then(json => this.setState({ exfiCandRewardData: json })).catch(err => console.log(err)),
            sfinCandPromise.then(response => response.json()).then(json => this.setState({ sfinCandRewardData: json })).catch(err => console.log(err)),
            exfiSgbPromise.then(response => response.json()).then(json => this.setState({ exfiSgbRewardData: json })).catch(err => console.log(err)),
            exfiSfinPromise.then(response => response.json()).then(json => this.setState({ exfiSfinRewardData: json })).catch(err => console.log(err)),
            exXdcSgbPromise.then(response => response.json()).then(json => this.setState({ exXdcSgbRewardData: json })).catch(err => console.log(err)),
            exXdcExfiPromise.then(response => response.json()).then(json => this.setState({ exXdcExfiRewardData: json })).catch(err => console.log(err)),
            exfiSfin90dPromise.then(response => response.json()).then(json => this.setState({ exfiSfin90dRewardData: json })).catch(err => console.log(err)),
            exfiSfin180dPromise.then(response => response.json()).then(json => this.setState({ exfiSfin180dRewardData: json })).catch(err => console.log(err)),
            exfiSfin180dV2Promise.then(response => response.json()).then(json => this.setState({ exfiSfin180dV2RewardData: json })).catch(err => console.log(err)),
            exfiSfin180dV3Promise.then(response => response.json()).then(json => this.setState({ exfiSfin180dV3RewardData: json })).catch(err => console.log(err)),
            wsgbPromise.then(response => response.json()).then(json => this.setState({ wsgbRewardData: json })).catch(err => console.log(err)),
            exfiPromise.then(response => response.json()).then(json => this.setState({ exfiRewardData: json })).catch(err => console.log(err)),
            exfi90dPromise.then(response => response.json()).then(json => this.setState({ exfi90dRewardData: json })).catch(err => console.log(err)),
            exfi180dPromise.then(response => response.json()).then(json => this.setState({ exfi180dRewardData: json })).catch(err => console.log(err)),
            exfi180dV2Promise.then(response => response.json()).then(json => this.setState({ exfi180dV2RewardData: json })).catch(err => console.log(err)),
            exfi180dV3Promise.then(response => response.json()).then(json => this.setState({ exfi180dV3RewardData: json })).catch(err => console.log(err)),
            exfi180dV4Promise.then(response => response.json()).then(json => this.setState({ exfi180dV4RewardData: json })).catch(err => console.log(err)),
            exfi180dV5Promise.then(response => response.json()).then(json => this.setState({ exfi180dV5RewardData: json })).catch(err => console.log(err)),
            exfi180dV6Promise.then(response => response.json()).then(json => this.setState({ exfi180dV6RewardData: json })).catch(err => console.log(err)),
            sfinPromise.then(response => response.json()).then(json => this.setState({ sfinRewardData: json })).catch(err => console.log(err)),
            candPromise.then(response => response.json()).then(json => this.setState({ candRewardData: json })).catch(err => console.log(err)),
            sgbSpPromise.then(response => response.json()).then(json => this.setState({ sgbSpRewardData: json })).catch(err => console.log(err)),
            exfiSpPromise.then(response => response.json()).then(json => this.setState({ exfiSpRewardData: json })).catch(err => console.log(err))
        ];
        await Promise.allSettled(tasks);

        localStorage.setItem('sgbCandRewardData', JSON.stringify(this.state.sgbCandRewardData));
        localStorage.setItem('sgbSfinRewardData', JSON.stringify(this.state.sgbSfinRewardData));
        localStorage.setItem('exfiCandRewardData', JSON.stringify(this.state.exfiCandRewardData));
        localStorage.setItem('sfinCandRewardData', JSON.stringify(this.state.sfinCandRewardData));
        localStorage.setItem('exfiSgbRewardData', JSON.stringify(this.state.exfiSgbRewardData));
        localStorage.setItem('exfiSfinRewardData', JSON.stringify(this.state.exfiSfinRewardData));
        localStorage.setItem('exXdcSgbRewardData', JSON.stringify(this.state.exXdcSgbRewardData));
        localStorage.setItem('exXdcExfiRewardData', JSON.stringify(this.state.exXdcExfiRewardData));
        localStorage.setItem('exfiSfin90dRewardData', JSON.stringify(this.state.exfiSfin90dRewardData));
        localStorage.setItem('exfiSfin180dRewardData', JSON.stringify(this.state.exfiSfin180dRewardData));
        localStorage.setItem('exfiSfin180dV2RewardData', JSON.stringify(this.state.exfiSfin180dV2RewardData));
        localStorage.setItem('exfiSfin180dV3RewardData', JSON.stringify(this.state.exfiSfin180dV2RewardData));
        localStorage.setItem('wsgbRewardData', JSON.stringify(this.state.wsgbRewardData));
        localStorage.setItem('exfiRewardData', JSON.stringify(this.state.exfiRewardData));
        localStorage.setItem('exfi90dRewardData', JSON.stringify(this.state.exfi90dRewardData));
        localStorage.setItem('exfi180dRewardData', JSON.stringify(this.state.exfi180dRewardData));
        localStorage.setItem('exfi180dV2RewardData', JSON.stringify(this.state.exfi180dV2RewardData));
        localStorage.setItem('exfi180dV3RewardData', JSON.stringify(this.state.exfi180dV3RewardData));
        localStorage.setItem('exfi180dV4RewardData', JSON.stringify(this.state.exfi180dV4RewardData));
        localStorage.setItem('exfi180dV5RewardData', JSON.stringify(this.state.exfi180dV5RewardData));
        localStorage.setItem('exfi180dV6RewardData', JSON.stringify(this.state.exfi180dV6RewardData));
        localStorage.setItem('sfinRewardData', JSON.stringify(this.state.sfinRewardData));
        localStorage.setItem('candRewardData', JSON.stringify(this.state.candRewardData));
        localStorage.setItem('sgbSpRewardData', JSON.stringify(this.state.sgbSpRewardData));
        localStorage.setItem('exfiSpRewardData', JSON.stringify(this.state.exfiSpRewardData));

        this.setState({
            loading: false
        });
    }

    render() {
        return (
            <Card raised className='rewardCard'>
                <div className='flex column'>
                    <div style={{ fontWeight: 'bold' }}>Your SFIN rewards</div>
                    <div className='sectionInfo' >Liquidity pools</div>
                    <TableContainer component={Card}>
                        <Table padding='none' sx={{ tableLayout: 'fixed', width: '100%' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold', width: '33%' }}>Token</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', width: '33%' }}>SFIN</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', width: '33%' }}>
                                        <Stack direction='row' spacing={0.5}>
                                            <span>CAND</span>
                                            <Tooltip title='The total CAND value when the SFIN was claimed'>
                                                <Icon sx={{ display: 'flex' }} color='info'>
                                                    <InfoIcon fontSize='small' sx={{ justifySelf: 'center', alignSelf: 'center' }} />
                                                </Icon>
                                            </Tooltip>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>SgbCand</TableCell>
                                    <TableCell>{this.state.sgbCandRewardData.sfinClaimed}</TableCell>
                                    <TableCell>{this.state.sgbCandRewardData.valueClaimed}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>SgbSfin</TableCell>
                                    <TableCell>{this.state.sgbSfinRewardData.sfinClaimed}</TableCell>
                                    <TableCell>{this.state.sgbSfinRewardData.valueClaimed}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>ExfiCand</TableCell>
                                    <TableCell>{this.state.exfiCandRewardData.sfinClaimed}</TableCell>
                                    <TableCell>{this.state.exfiCandRewardData.valueClaimed}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>SfinCand</TableCell>
                                    <TableCell>{this.state.sfinCandRewardData.sfinClaimed}</TableCell>
                                    <TableCell>{this.state.sfinCandRewardData.valueClaimed}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>ExfiSgb</TableCell>
                                    <TableCell>{this.state.exfiSgbRewardData.sfinClaimed}</TableCell>
                                    <TableCell>{this.state.exfiSgbRewardData.valueClaimed}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>ExfiSfin</TableCell>
                                    <TableCell>{this.state.exfiSfinRewardData.sfinClaimed}</TableCell>
                                    <TableCell>{this.state.exfiSfinRewardData.valueClaimed}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>ExXdcSgb</TableCell>
                                    <TableCell>{this.state.exXdcSgbRewardData.sfinClaimed}</TableCell>
                                    <TableCell>{this.state.exXdcSgbRewardData.valueClaimed}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>ExXdcExfi</TableCell>
                                    <TableCell>{this.state.exXdcExfiRewardData.sfinClaimed}</TableCell>
                                    <TableCell>{this.state.exXdcExfiRewardData.valueClaimed}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>ExfiSfin (90d)</TableCell>
                                    <TableCell>{this.state.exfiSfin90dRewardData.sfinClaimed}</TableCell>
                                    <TableCell>{this.state.exfiSfin90dRewardData.valueClaimed}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>ExfiSfin (180d)</TableCell>
                                    <TableCell>{this.state.exfiSfin180dRewardData.sfinClaimed}</TableCell>
                                    <TableCell>{this.state.exfiSfin180dRewardData.valueClaimed}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>ExfiSfin (180d v2)</TableCell>
                                    <TableCell>{this.state.exfiSfin180dV2RewardData.sfinClaimed}</TableCell>
                                    <TableCell>{this.state.exfiSfin180dV2RewardData.valueClaimed}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>ExfiSfin (180d v3)</TableCell>
                                    <TableCell>{this.state.exfiSfin180dV3RewardData.sfinClaimed}</TableCell>
                                    <TableCell>{this.state.exfiSfin180dV3RewardData.valueClaimed}</TableCell>
                                </TableRow>
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableCell>Sum</TableCell>
                                    <TableCell>{(this.state.sgbCandRewardData.sfinClaimed + this.state.exfiCandRewardData.sfinClaimed + this.state.sfinCandRewardData.sfinClaimed + this.state.exfiSgbRewardData.sfinClaimed + this.state.exfiSfinRewardData.sfinClaimed).toFixed(4)}</TableCell>
                                    <TableCell>{this.state.sgbCandRewardData.valueClaimed + this.state.exfiCandRewardData.valueClaimed + this.state.sfinCandRewardData.valueClaimed + this.state.exfiSgbRewardData.valueClaimed + this.state.exfiSfinRewardData.valueClaimed}</TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                    <div className='sectionInfo'>Token staking</div>
                    <TableContainer component={Card}>
                        <Table padding='none' sx={{ tableLayout: 'fixed', width: '100%' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold', width: '33%' }}>Token</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', width: '33%' }}>SFIN</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', width: '33%' }}>
                                        <Stack direction='row' spacing={0.5}>
                                            <span>CAND</span>
                                            <Tooltip title='The total CAND value when the SFIN was claimed'>
                                                <Icon sx={{ display: 'flex' }} color='info'>
                                                    <InfoIcon fontSize='small' sx={{ justifySelf: 'center', alignSelf: 'center' }} />
                                                </Icon>
                                            </Tooltip>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Wsgb</TableCell>
                                    <TableCell>{this.state.wsgbRewardData.sfinClaimed}</TableCell>
                                    <TableCell>{this.state.wsgbRewardData.valueClaimed}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Exfi</TableCell>
                                    <TableCell>{this.state.exfiRewardData.sfinClaimed}</TableCell>
                                    <TableCell>{this.state.exfiRewardData.valueClaimed}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Exfi (90d v1)</TableCell>
                                    <TableCell>{this.state.exfi90dRewardData.sfinClaimed}</TableCell>
                                    <TableCell>{this.state.exfi90dRewardData.valueClaimed}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Exfi (180d v1)</TableCell>
                                    <TableCell>{this.state.exfi180dRewardData.sfinClaimed}</TableCell>
                                    <TableCell>{this.state.exfi180dRewardData.valueClaimed}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Exfi (180d v2)</TableCell>
                                    <TableCell>{this.state.exfi180dV2RewardData.sfinClaimed}</TableCell>
                                    <TableCell>{this.state.exfi180dV2RewardData.valueClaimed}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Exfi (180d v3)</TableCell>
                                    <TableCell>{this.state.exfi180dV3RewardData.sfinClaimed}</TableCell>
                                    <TableCell>{this.state.exfi180dV3RewardData.valueClaimed}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Exfi (180d v4)</TableCell>
                                    <TableCell>{this.state.exfi180dV4RewardData.sfinClaimed}</TableCell>
                                    <TableCell>{this.state.exfi180dV4RewardData.valueClaimed}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Exfi (180d v5)</TableCell>
                                    <TableCell>{this.state.exfi180dV5RewardData.sfinClaimed}</TableCell>
                                    <TableCell>{this.state.exfi180dV5RewardData.valueClaimed}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Exfi (180d v6)</TableCell>
                                    <TableCell>{this.state.exfi180dV6RewardData.sfinClaimed}</TableCell>
                                    <TableCell>{this.state.exfi180dV6RewardData.valueClaimed}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Sfin</TableCell>
                                    <TableCell>{this.state.sfinRewardData.sfinClaimed}</TableCell>
                                    <TableCell>{this.state.sfinRewardData.valueClaimed}</TableCell>
                                </TableRow>
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableCell>Sum</TableCell>
                                    <TableCell>{(this.state.wsgbRewardData.sfinClaimed + this.state.exfiRewardData.sfinClaimed + this.state.sfinRewardData.sfinClaimed).toFixed(4)}</TableCell>
                                    <TableCell>{this.state.wsgbRewardData.valueClaimed + this.state.exfiRewardData.valueClaimed + this.state.sfinRewardData.valueClaimed}</TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                    <div className='sectionInfo'>Stability pools</div>
                    <TableContainer component={Card}>
                        <Table padding='none' sx={{ tableLayout: 'fixed', width: '100%' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold', width: '33%' }}>Loan type</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', width: '33%' }}>SFIN</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', width: '33%' }}>
                                        <Stack direction='row' spacing={0.5}>
                                            <span>CAND</span>
                                            <Tooltip title='The total CAND value when the SFIN was claimed'>
                                                <Icon sx={{ display: 'flex' }} color='info'>
                                                    <InfoIcon fontSize='small' sx={{ justifySelf: 'center', alignSelf: 'center' }} />
                                                </Icon>
                                            </Tooltip>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Sgb</TableCell>
                                    <TableCell>{this.state.sgbSpRewardData.sfinClaimed}</TableCell>
                                    <TableCell>{this.state.sgbSpRewardData.valueClaimed}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Exfi</TableCell>
                                    <TableCell>{this.state.exfiSpRewardData.sfinClaimed}</TableCell>
                                    <TableCell>{this.state.exfiSpRewardData.valueClaimed}</TableCell>
                                </TableRow>
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableCell>Sum</TableCell>
                                    <TableCell>{(this.state.sgbSpRewardData.sfinClaimed + this.state.exfiSpRewardData.sfinClaimed).toFixed(4)}</TableCell>
                                    <TableCell>{this.state.sgbSpRewardData.valueClaimed + this.state.exfiSpRewardData.valueClaimed}</TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                    <div style={{ alignSelf: 'flex-end', marginTop: '10px' }}>
                        <LoadingButton loading={this.state.loading} size='small' variant='contained' onClick={() => this.updateClicked()}>Update</LoadingButton>
                    </div>
                </div>
            </Card>
        );
    }
}

export default RewardInfoCard;